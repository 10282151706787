const RouteMenu = (userId) => {
  return [
    {
      path: '/index',
      name: 'menu.dashboard',
      icon: '',
    },
    {
      path: '/member-management',
      name: 'menu.memberManagement',
      icon: '',
      links: ['/detail/*'],
      role: 'admin',
    },
  ];
};

export default RouteMenu;
