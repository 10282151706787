import React, { useState, useRef, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

import * as COLOR from 'constants/ColorForm.js';
import { styled } from '@mui/material/styles';

const CssTextField = styled(TextField)({
  '& label': {
    fontSize: '14px',
    display: 'none',
  },
  '& label.Mui-focused': {
    color: COLOR.GREEN,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: COLOR.GREEN,
  },
  '& .MuiInputAdornment-root ': {
    paddingTop: '2px',
  },
  '& .MuiSvgIcon-root': {
    color: COLOR.YELLOW,
    paddingRight: '10px',
    fontSize: '35px',
  },
  '& .MuiOutlinedInput-root': {
    paddingLeft: '14px',
    fontSize: '14px',

    '& fieldset': {
      borderColor: COLOR.WHEAT,
    },
    '& fieldset legend': {
      width: 0,
    },

    '&:hover fieldset': {
      borderColor: COLOR.GREEN,
    },
    '&.Mui-focused fieldset': {
      borderColor: COLOR.GREEN,
    },

    '&.Mui-disabled fieldset': {
      borderColor: COLOR.WHEAT,
    },
    '&.Mui-disabled:hover fieldset': {
      borderColor: COLOR.WHEAT,
    },
  },
});

// render
const Search = (props) => {
  const { txtPlaceholder, addClass, handleSearch, maxLength, defaultValue } = props;

  const timer = useRef();
  const [keyword, setKeyword] = useState(defaultValue);

  useEffect(() => {
    if (!defaultValue) setKeyword('');
  }, [defaultValue]);

  const handleChange = (event) => {
    setKeyword(event.target.value);

    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      handleSearch(event.target.value);
    }, 300);
  };

  return (
    <div className={addClass}>
      <CssTextField
        label="."
        placeholder={txtPlaceholder}
        size="small"
        type="search"
        onChange={handleChange}
        maxLength={maxLength}
        value={keyword}
        defaultValue={defaultValue}
        InputProps={{
          startAdornment: (
            <InputAdornment>
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default Search;
