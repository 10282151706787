/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { createContext, useRef } from 'react';
import { useLocation, Route, Switch, Redirect } from 'react-router-dom';
import { useQueryClient } from 'react-query';
// reactstrap components
import { Container } from 'reactstrap';
// core components
import AdminFooter from 'components/Footers/index.js';
import Header from '../components/Headers/Header.js';
import { useInforAuth } from 'hook/useAuth';
import routes from 'routes.js';
import { toasts } from '../index';
import { USE_GET_NOTIFICATIONS } from 'constants/index.js';
import { updateNotificationViewRow } from 'api/notification.js';
import imgAvatar from 'assets/img/theme/avatar_default.png';
import configs from 'config';

export const ThemContext = createContext();

const SSO = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const queryClient = useQueryClient();
  window.OneSignal = window.OneSignal || [];
  const OneSignal = window.OneSignal;
  const notificationUpdate = useRef(null);
  const { data: dataInforUser } = useInforAuth();

  React.useEffect(() => {
    if (dataInforUser?.user_id) {
      OneSignal.push(function () {
        OneSignal.init({
          appId: configs.ID_ONSINAL,
          safari_web_id: configs.ID_SAFARI_ID,
          notifyButton: {
            enable: true,
          },
          subdomainName: 'acm-test4',
        });
        OneSignal.setExternalUserId(String(dataInforUser?.user_id));
        OneSignal.getExternalUserId().then();
        OneSignal.on('notificationDisplay', function (event) {
          queryClient.invalidateQueries(USE_GET_NOTIFICATIONS);
          toasts.info(
            <div className="onsignal" onMouseOver={() => updateNotification(event?.data?.id)}>
              <img
                className="avatar-noti-onsignal"
                alt={event?.data?.sender_avatar ? event?.data?.sender_avatar : imgAvatar}
                src={event?.data?.sender_avatar ? event?.data?.sender_avatar : imgAvatar}
              />
              <span>{event.content}</span>
            </div>,
            {
              position: 'bottom-left',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'dark',
            },
          );
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataInforUser]);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      return <Route path={prop.path} component={prop.component} key={key} />;
    });
  };

  const updateNotification = async (id) => {
    if (notificationUpdate.current !== id) {
      notificationUpdate.current = id;
      await updateNotificationViewRow({ notification_id: id, user_id: dataInforUser?.user_id });
      queryClient.invalidateQueries(USE_GET_NOTIFICATIONS);
    }
  };

  return (
    <>
      <ThemContext.Provider value={dataInforUser}>
        <Header routes={routes} />
        <div className="main-content" ref={mainContent}>
          <Container className="" fluid>
            <Switch>
              {getRoutes(routes)}
              <Redirect from="*" to="/index" />
            </Switch>
          </Container>
          <Container fluid className="mb-6">
            <AdminFooter />
          </Container>
        </div>
      </ThemContext.Provider>
    </>
  );
};

export default SSO;
