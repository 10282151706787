const configs = {
  API_DOMAIN: process.env.REACT_APP_SSO_API_DOMAIN,
  API_DOMAIN_HR: process.env.REACT_APP_API_DOMAIN,
  API_DOMAIN_ME: process.env.REACT_APP_ME_API_DOMAIN,
  API_DOMAIN_AMS: process.env.REACT_APP_API_DOMAIN_AMS,
  API_TOKEN_AMS: process.env.REACT_APP_API_TOKEN_AMS,
  API_DOMAIN_BWF: process.env.REACT_APP_API_DOMAIN_BWF,
  API_DOMAIN_LOGIN: process.env.REACT_APP_DOMAIN_LOGIN,
  API_DOMAIN_NOTIFICATIONS: process.env.REACT_APP_API_NOTIFICATIONS,
  DOMAIN_AMS_BO: process.env.REACT_APP_DOMAIN_AMS_BO,
  DOMAIN_AMS_ME: process.env.REACT_APP_DOMAIN_AMS_ME,
  DOMAIN_AMS_SSO: process.env.REACT_APP_DOMAIN_AMS_SSO,
  DOMAIN_AMS_RECRUIT: process.env.REACT_APP_DOMAIN_AMS_RECRUIT,
  DOMAIN_AMS_PROJECT: process.env.REACT_APP_DOMAIN_AMS_PROJECT,
  DOMAIN_AMS_DIVISION: process.env.REACT_APP_DOMAIN_AMS_DIVISION,
  DOMAIN_AMS_WORKFLOW: process.env.REACT_APP_DOMAIN_AMS_WORKFLOW,
  DOMAIN_AMS_ACCOUNTING: process.env.REACT_APP_DOMAIN_AMS_ACCOUNTING,
  CLIENT_ID_MICROSOFT: process.env.REACT_APP_CLIENT_ID_MICROSOFT,
  SUBDOMAIN_TOKEN: process.env.REACT_APP_CLIENT_SUBDOMAIN_TOKEN,
  ID_ONSINAL: process.env.REACT_APP_ONSIGNAL_SSO_ID,
  ID_SAFARI_ID: process.env.REACT_APP_ONSIGNAL_SAFARI_WEB_SSO_ID,
  DOMAIN_AMS_WIKI: process.env.REACT_APP_WIKI,
};

export default configs;
