import Axios from 'axios';
import config from './../config';
import configEnv from '../config';
// import { history } from "../Root";
import storageFC from 'helpers/cookie.js';
import { HTTP_NOT_ACCESS } from 'constants/StatusCode.js';

const USER_TOKEN_KEY = 'token_bwf';

const axiosInstance = Axios.create({
  baseURL: configEnv.API_DOMAIN_BWF,
  timeout: 6000,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    storageFC.setToken(configEnv.API_TOKEN_AMS, USER_TOKEN_KEY);
    config.headers.Authorization = `Bearer ${storageFC.getToken(USER_TOKEN_KEY)}`;
    return config;
  },
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // if (error.response.status !== 404) {
    //   history.push("/not-found");
    // }
    if (error?.response?.status === 401) {
      storageFC.setToken('');
      window.location.href = config.DOMAIN_AMS_SSO;
    }

    if (error?.response?.status === HTTP_NOT_ACCESS) {
      window.location.href = config.DOMAIN_AMS_SSO + '/page-not-access';
    }

    return error.response;
  },
);

export const sendGet = (url = '', params) => axiosInstance.get(url, { params }).then((res) => res);
export const sendPost = (url = '', params, queryParams) =>
  axiosInstance.post(url, params, { params: queryParams }).then((res) => res);
export const sendPut = (url = '', params) => axiosInstance.put(url, params).then((res) => res);
export const sendPatch = (url = '', params) => axiosInstance.patch(url, params).then((res) => res);
export const sendDelete = (url = '', params) => axiosInstance.delete(url, { data: params }).then((res) => res);
export const sendCustom = (params = {}) => axiosInstance(params).then((res) => res);
