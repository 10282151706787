import { NavLink as NavLinkRRD, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import React from 'react';
import axios from 'axios';
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledDropdown,
  Media,
} from 'reactstrap';
import RouteMenu from 'routeHeader.js';
import styles from './styles.module.scss';
import classNames from 'classnames';

import Notification from 'components/NotificationAgain';
import HomeMini from 'components/HomeMini';
import { confirmAlert } from 'react-confirm-alert';
import cookieFC from 'helpers/cookie';
import configs from 'config';
import { useInforAuth } from 'hook/useAuth';
import logoutIcon from 'assets/img/icons/logout.svg';
import defaultAvatar from 'assets/img/icons/default-avatar.svg';
import { NOT_ROLE_ADMIN, ADMIN } from 'constants/index.js';
import { handleOnError } from 'helpers';

const Header = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();

  const { data: dataInforUser } = useInforAuth();
  const routes = RouteMenu(dataInforUser?.user_id);

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const getNavStyles = (links) => {
    let isActive = '';
    if (links) {
      links.forEach((link) => {
        let urlLink = link;
        if (
          urlLink === location.pathname ||
          (urlLink.indexOf('*') > 0 && location.pathname.indexOf(urlLink.slice(0, -1)) >= 0)
        ) {
          isActive = 'active';
        }
      });
    }
    return isActive;
  };

  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop?.role === ADMIN && dataInforUser?.role === NOT_ROLE_ADMIN) {
        return '';
      }
      return (
        <NavItem key={key} className={prop?.class}>
          <NavLink
            to={prop.path}
            tag={NavLinkRRD}
            activeClassName="active"
            className={classNames(`${prop?.class} ${getNavStyles(prop.links)}`)}
          >
            {prop.icon ? <i className={prop.icon} /> : ''}
            {t(prop.name)}
          </NavLink>
        </NavItem>
      );
    });
  };

  const logout = () => {
    confirmAlert({
      title: t('common.areYouSureYouWantToSignOut'),
      buttons: [
        {
          label: t('common.cancel'),
          className: 'btn-alert-cancel',
        },
        {
          label: t('common.confirm'),
          className: 'btn-alert-ok',
          onClick: async () => {
            axios
              .post(
                `${configs.API_DOMAIN}/api/logout`,
                {
                  token: cookieFC.getItem('token'),
                },
                {
                  headers: { Authorization: `Bearer ${cookieFC.getItem('token')}` },
                },
              )
              .then((res) => {
                cookieFC.setToken('');
                history.push('/auth/login');
              })
              .catch((error) => {
                console.log(error);
              });
          },
        },
      ],
    });
  };

  return (
    <>
      <div className={classNames(`header bg-menu ${styles.boxMenu}`)}>
        <Container fluid className={styles.menu}>
          <div className="header-body">
            <Navbar className="navbar-top navbar-horizontal navbar-dark" expand="lg">
              <>
                <NavbarBrand to="/" tag={Link}>
                  <div className={styles.logo}>
                    <img alt="logo" src={require('../../assets/img/icons/logo.png').default} />
                    <h4>SSO</h4>
                  </div>
                </NavbarBrand>
                <button className="navbar-toggler" id="navbar-collapse-main">
                  <span className="navbar-toggler-icon" />
                </button>
                <UncontrolledCollapse navbar toggler="#navbar-collapse-main">
                  <div className={styles.menuLeft}>{dataInforUser && <Nav navbar>{createLinks(routes)}</Nav>}</div>
                  <Nav className="ml-auto" navbar>
                    <UncontrolledDropdown nav>
                      <Media className="align-items-center">
                        <span className="avatar avatar-sm rounded-circle">
                          <img
                            onError={handleOnError}
                            alt="..."
                            src={dataInforUser?.avatar ? dataInforUser?.avatar : defaultAvatar}
                          />
                        </span>
                      </Media>
                    </UncontrolledDropdown>
                    <UncontrolledDropdown nav>
                      <Notification toggleDrawer={toggleDrawer} state={state} />
                    </UncontrolledDropdown>
                    <UncontrolledDropdown nav>
                      <HomeMini />
                    </UncontrolledDropdown>
                    <NavItem className={styles.logOut}>
                      {cookieFC.getItem('token') && (
                        <span className={styles.logout} onClick={logout}>
                          <img alt={logoutIcon} src={logoutIcon} />
                        </span>
                      )}
                    </NavItem>
                  </Nav>
                </UncontrolledCollapse>
              </>
            </Navbar>
          </div>
        </Container>
      </div>
      <div className={styles.paddingTop}></div>
    </>
  );
};

export default Header;
