import { toasts } from '../index';

import { sendCustom } from 'helpers/axios';
import moment from 'moment';
import avatarDefault from 'assets/img/icons/default-avatar.svg';

export const isNotNullObject = (object) => {
  return Object.entries(object).length !== 0;
};

export const handleOnError = (e) => {
  e.target.src = avatarDefault;
};

export const isEmptyObject = (object) => {
  return object === {};
};

export const isNumberAnode = (value) => {
  if (Number(value) !== 'NaN' && !value.includes('.') && value >= 0) return true;
  return false;
};

export const isFloatAnode = (value) => {
  if (/^(\d*)([.]\d{0,1})?$/.test(value)) return true;
  return false;
};

export const isNumberCurrency = (value) => {
  let _value = value.toString().replace(/,/g, '');
  _value = _value.toString().replace(/./g, '');
  if (/^(\d*)([,]\d{0,1})?$/.test(_value)) return true;
  return false;
};

export const getStrError = (value) => {
  if (!value) return;
  if (typeof value === 'string') {
    return value;
  }
  return value[0];
};

export const checkValidate = (dataForm, dataValidate) => {
  let dataError = {};

  dataValidate.forEach((elements) => {
    elements.validate.forEach((validate) => {
      // check required
      if (validate?.required) {
        if (!dataForm[elements.name]) {
          dataError[elements.name_error] = validate.message;
          return;
        }
      }

      // check length
      if (validate?.length) {
        let str = dataForm[elements.name];
        if (typeof str === 'string' && str.length > validate.length) {
          dataError[elements.name_error] = validate.message;
          return;
        }
      }
    });
  });

  return dataError;
};

export const checkErrorResponse = (dataResponseMsg, dataValidate) => {
  let dataError = {};
  if (!dataResponseMsg) return dataError;

  dataValidate.forEach((elements) => {
    dataError[elements.name_error] = getStrError(dataResponseMsg?.[elements.name]);
  });

  return dataError;
};

export const removeSpecialCharacters = (value) => {
  return value.replace(/[&\\#@^|;_`,+()$~%.'":*?/1234567890<>={}-]/g, '');
};

export const handleExportExcel = async (url, params, fileName, messageError) => {
  try {
    const res = await sendCustom({
      url,
      params,
      responseType: 'blob',
    });

    if (res.status === 422) {
      return toasts.error(messageError ? messageError : 'Vui lòng kiểm tra lại điều kiện lọc dữ liệu');
    }

    const blob = new Blob([res.data], { type: res.data.type });
    const link = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.setAttribute('download', fileName);
    a.setAttribute('href', link);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } catch (error) {}
};

export const convertFormatTimeSend = (name, type) => {
  return moment(name).format(type);
};
