import { useQuery } from 'react-query';

import { HTTP_OK } from './../constants/StatusCode.js';
import * as Constants from './../constants/DetailEmployee.js';
import { apiGetInforUser, apiGetPermissionUserInBo, apiGetPermissionUserInWorkFollow } from 'api/detailEmployee';

export function useGetInforUser(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [Constants.USE_GET_INFOR_USER, params],
    async () => {
      const response = await apiGetInforUser(params);
      if (response.status === HTTP_OK) {
        return response;
      }

      return null;
    },
    { enabled: params.user_id ? true : false },
  );

  return { ...response, error, isLoading };
}

export function useGetPermissionUserInBo(userId) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [Constants.USE_GET_PERMISSION_USER_IN_BO, userId],
    async () => {
      const response = await apiGetPermissionUserInBo(userId);
      if (response.status === HTTP_OK) {
        return response;
      }

      return null;
    },
    { enabled: userId ? true : false },
  );

  return { ...response, error, isLoading };
}

export function useGetPermissionUserInWorkFollow(userId) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [Constants.USE_GET_PERMISSION_USER_IN_WORKFLOW, userId],
    async () => {
      const response = await apiGetPermissionUserInWorkFollow(userId);
      if (response.status === HTTP_OK) {
        return response;
      }

      return null;
    },
    { enabled: userId ? true : false },
  );

  return { ...response, error, isLoading };
}
