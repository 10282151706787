import { useQuery } from 'react-query';

import { HTTP_OK } from './../constants/StatusCode.js';
import * as Constants from './../constants/MemberManagement';
import { apiListUser } from 'api/listEmployee';

export function useGetListUser(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_GET_LIST_USER, params], async () => {
    const response = await apiListUser(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }

    return null;
  });

  return { ...response, error, isLoading };
}
