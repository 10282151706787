import React, { useCallback, useState } from 'react';
import { Grid, debounce, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import { useForm, useWatch } from 'react-hook-form';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';

import iconChecked from 'assets/img/icons/checked-green.svg';

import styles from './style.module.scss';
import SearchComponent from 'components/Search';
import { DEFAULT_LIMIT_20, DEFAULT_PAGE_MUI, PER_PAGE_OPTION_20 } from 'constants/index.js';
import { ZERO_STATUS } from 'constants/MemberManagement';
import { useGetListUser } from '../../../hook/useListEmployee.js';
import { LOADING_MD } from 'constants/index.js';
import Loading from 'components/Loading';
import SelectField from 'components/ReactForm/SelectField';
import { ACTIVE_STATUS } from 'constants/MemberManagement';
import { INACTIVE_STATUS } from 'constants/MemberManagement';
import { ALL_STATUS } from 'constants/MemberManagement';

const MemberManagement = () => {
  const { t } = useTranslation();

  const { control, register, setValue } = useForm();

  const [filter, setFilter] = useState({
    key_word: null,
    status: null,
    page: DEFAULT_PAGE_MUI,
    per_page: DEFAULT_LIMIT_20,
  });

  const { data: uListMember, isLoading: isLoadingGetListMember } = useGetListUser({
    ...filter,
    status: Number(filter.status) !== -1 ? filter.status : null,
    page: filter.page + 1,
  });
  // Handle Filter
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce((value) => {
      setFilter({ ...filter, key_word: value, page: DEFAULT_PAGE_MUI });
    }),
  );

  const handleChangePage = (event, newPage) => {
    setFilter({ ...filter, page: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setFilter({ ...filter, page: DEFAULT_PAGE_MUI, per_page: +event.target.value });
  };

  return (
    <>
      <div className={styles.content}>
        <Grid container className="nav-fixed">
          <Grid container className="align-items-center">
            <Grid item md={9} xs={12}>
              <div className={styles.title}>
                <div className={styles.labelTitle}></div>
                <div className={styles.textTitle}>{t('memberManagement.listMember')}</div>
              </div>
            </Grid>
            <Grid item className={`button text-right ${styles.filter}`} md={3} xs>
              <div className={styles.search}>
                <SearchComponent
                  txtPlaceholder={t('memberManagement.findMemberEmail')}
                  addClass="input-search-box"
                  handleSearch={handleSearch}
                  defaultValue={filter?.key_word}
                />
              </div>
              <div className={styles.fieldSelect}>
                <SelectField
                  name="warehouse_id"
                  label={t('memberManagement.status')}
                  defaultValue={filter.status ? Number(filter.status) : filter.status}
                  onHandleSelect={(value) => {
                    setFilter((prev) => {
                      return { ...prev, status: Number(value.target.value) };
                    });
                  }}
                  control={control}
                  required
                  setValue={setValue}
                  useWatch={useWatch}
                  register={register}
                  isShowModal={true}
                >
                  <MenuItem value={ALL_STATUS} key={ALL_STATUS}>
                    {t('common.all')}
                  </MenuItem>
                  <MenuItem value={ACTIVE_STATUS} key={ACTIVE_STATUS}>
                    {t('memberManagement.active')}
                  </MenuItem>
                  <MenuItem value={INACTIVE_STATUS} key={INACTIVE_STATUS}>
                    {t('memberManagement.inactive')}
                  </MenuItem>
                </SelectField>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={12} className={styles.assetMain}>
            <div className={styles.profileBox}>
              <Paper>
                <TableContainer>
                  <Table aria-label="sticky table">
                    <TableHead className={styles.assetHeader}>
                      <TableRow className={styles.assetHeaderRow}>
                        <TableCell align="center">{t('memberManagement.code')}</TableCell>
                        <TableCell align="center" colSpan={2}>
                          {t('memberManagement.name')}
                        </TableCell>
                        <TableCell colSpan={2} align="center">
                          {t('memberManagement.createdDate')}
                        </TableCell>
                        <TableCell colSpan={2} align="center">
                          {t('memberManagement.statusAccount')}
                        </TableCell>
                        <TableCell align="center" colSpan={5}>
                          {t('memberManagement.role')}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {uListMember?.data && uListMember?.data?.length === 0 ? (
                        <tr>
                          <td colSpan={12} className="error-no-data">
                            {t('common.notData')}
                          </td>
                        </tr>
                      ) : (
                        uListMember?.data?.length > 0 &&
                        uListMember?.data?.map((item, index) => {
                          return (
                            <TableRow
                              hover
                              onClick={() => {
                                item.handelClick.click();
                              }}
                              role="checkbox"
                              tabIndex={-1}
                              key={index}
                            >
                              <TableCell>
                                {' '}
                                <NavLink
                                  to={{
                                    pathname: '/detail/' + item?.user_id,
                                  }}
                                  className={styles.linkRequest}
                                  ref={(input) => {
                                    item.handelClick = input;
                                  }}
                                >
                                  {item?.code}
                                </NavLink>
                              </TableCell>
                              <TableCell colSpan={2}>
                                <div className={styles.linkRequest}>
                                  {item?.name ? item?.name : <>{t('common.notData')}</>}
                                </div>
                                <div className={styles.email}>
                                  {item?.email ? item?.email : <>{t('common.notData')}</>}
                                </div>
                              </TableCell>
                              <TableCell colSpan={2}>
                                {item?.created_at ? (
                                  moment(item?.created_at).format('DD/MM/YYYY')
                                ) : (
                                  <>{t('common.notData')}</>
                                )}
                              </TableCell>

                              <TableCell colSpan={2}>
                                {item?.status === ZERO_STATUS ? (
                                  <div>{t('memberManagement.inactive')}</div>
                                ) : (
                                  <div>{t('memberManagement.active')}</div>
                                )}
                              </TableCell>
                              <TableCell colSpan={5}>
                                {item?.role === ZERO_STATUS ? '' : <img alt={iconChecked} src={iconChecked} />}
                              </TableCell>
                            </TableRow>
                          );
                        })
                      )}
                      {isLoadingGetListMember && (
                        <tr>
                          <td colSpan={12}>
                            <Loading addClass={`loading ${styles.loading}`} type={LOADING_MD} />
                          </td>
                        </tr>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className={styles.tablePagination}>
                  {uListMember?.total > 0 && (
                    <TablePagination
                      rowsPerPageOptions={PER_PAGE_OPTION_20}
                      component="div"
                      count={uListMember?.total}
                      rowsPerPage={filter?.per_page}
                      page={filter?.page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  )}
                </div>
              </Paper>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default MemberManagement;
