import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import MicrosoftLogin from 'react-microsoft-login';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import IconSlogan from 'assets/img/icons/icon_slogan.svg';
import axios from 'axios';
import configs from 'config';
import styles from './styles.module.scss';
import AlertComponent from 'components/Alert';
import cookieFC from 'helpers/cookie';
import Loading from 'components/Loading';

const Auth = () => {
  const { t } = useTranslation();
  const isClickButtonLogin = useRef(false);
  const history = useHistory();
  const [message, setMessage] = useState('');
  const [isShowAlert, setShowAlert] = useState(false);
  const [onClick, setOnClick] = useState(false);

  useEffect(() => {
    if (cookieFC.getItem('token')) {
      history.push('/index');
    }
    // Handle Filter
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const authHandler = (err, data) => {
    if (isClickButtonLogin.current) {
      if (data?.accessToken) {
        setOnClick(true);
        axios
          .post(`${configs.API_DOMAIN}/api/login`, { token: data?.accessToken })
          .then((res) => {
            if (res.data.token) {
              cookieFC.setItem('token', res.data.token);
              if (cookieFC.getItem('previous_url')) {
                let dataRedirect = cookieFC.getItem('previous_url');
                cookieFC.setItem('previous_url', '');
                window.location = dataRedirect;
              } else {
                history.push('/index');
              }
              setOnClick(false);
            }
          })
          .catch((error) => {
            error?.response?.data?.messages && setMessage(error?.response?.data?.messages);
            error?.response?.data?.messages && setOnClick(false);
            error?.response?.data?.messages && setShowAlert(true);
          });
      }
    }
    isClickButtonLogin.current = false;
  };

  return (
    <div className={styles.pageLogin}>
      {onClick && <Loading addClass={styles.loading} />}
      <Box sx={{ flexGrow: 1 }} className={onClick && styles.blur}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={6}>
            <div className={styles.boxVideo}>
              <video loop={true} autoPlay={true} muted={true}>
                <source src="https://amela.vn/wp-content/uploads/2021/07/video_pc.mp4" type="video/mp4" />
              </video>
            </div>
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <div className={styles.boxLogin}>
              <div className={styles.with100}>
                <div className={styles.title}>
                  <span></span>
                  {t('common.login')}
                </div>
                <div className={styles.slogan}>
                  <p>{t('slogan.youthful')}</p>
                  <img src={IconSlogan} alt={IconSlogan} />
                  <p>{t('slogan.creative')}</p>
                  <img src={IconSlogan} alt={IconSlogan} />
                  <p>{t('slogan.enthusiasm')}</p>
                  <img src={IconSlogan} alt={IconSlogan} />
                  <p>{t('slogan.responsibility')}</p>
                </div>
                <div className={styles.buttonLogin}>
                  <MicrosoftLogin clientId={configs.CLIENT_ID_MICROSOFT} authCallback={authHandler}>
                    <Button
                      variant="contained"
                      onClick={() => {
                        isClickButtonLogin.current = true;
                      }}
                    >
                      {t('home.SignInViaMicrosoft')}
                    </Button>
                  </MicrosoftLogin>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
      <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
        {message}
      </AlertComponent>
    </div>
  );
};

export default Auth;
