export const BG_LEVEL = ['#2ECC71', '#FEA628', '#223354', '#FFF8EE', '#E74C3C', '#222433'];
export const BG_POSITION = [
  '#FEA628',
  '#223354',
  '#526487',
  '#222433',
  '#2ECC71',
  '#0038FF',
  '#E74C3C',
  '#FF91AF',
  '#DA1884',
  '#9C2542',
  '#7C0A02',
];
export const BG_CONTRACT_CATEGORY_CONTRACT = [
  '#FEA628',
  '#E74C3C',
  '#223354',
  '#FFF8ED',
  '#2ECC71',
  '#0038FF',
  '#E74C3C',
  '#008000',
  '#8DB600',
  '#FBCEB1',
  '#00FFFF',
  '#7FFFD4',
  '#D0FF14',
  '#4B5320',
  '#8F9779',
  '#E9D66B',
];
export const BG_CONTRACT_CATEGORY_EMPLOYEES = ['#FEA628', '#2ECC71', '#223354', '#E74C3C', '#0038FF', '#E74C3C'];
