import Dashboard from 'views/pages/Dashboard';
import MemberManagement from 'views/pages/MemberManagement';
import DetailEmployee from 'views/pages/DetailEmployee';
import PageNotAccess from 'views/pages/PageNotAccess';
import { ADMIN } from 'constants/index.js';

var routesMenu = [
  {
    path: '/index',
    name: 'menu.dashboard',
    icon: '',
    component: Dashboard,
  },
  {
    path: '/member-management',
    name: 'menu.memberManagement',
    icon: '',
    component: MemberManagement,
    role: ADMIN,
  },
];

var routeNav = [
  {
    path: '/auth/login',
    name: 'menu.Login',
    icon: '',
    component: '',
    class: 'hidden',
  },
  {
    path: '/detail/:userId',
    name: 'profile',
    icon: '',
    component: DetailEmployee,
    class: 'hidden',
  },
  {
    path: '/page-not-access',
    name: 'common.pageNotAccess',
    icon: '',
    component: PageNotAccess,
    class: 'hidden',
  },
];

const routes = [...routesMenu, ...routeNav];

export default routes;
