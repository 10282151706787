export const TYPE_LINK = {
  DETAIL_POST: 3,
  DETAIL_POST_GROUP: 4,
  DETAIL_REPORT: 5,
  SERIES: 6,
  GROUP_APPROVE_MEMBER: 7,
  GROUP_APPROVE_POST: 8,
  GROUP_LIST_REPORT: 9,
  DETAIL_COMMENT: 10,
}

export const TAB_GROUP = {
  NEW_FEED: 0,
  LIST_WAITING: 1,
  LIST_REPORT: 2,
  REQUEST_JOIN: 3,
}

export  const TYPE_LINK_RECRUIT = {
  DETAIL_APPLY_INTERVIEW_LEADER: 11,
  DETAIL_INTERVIEW_SCHEDULE: 12,
  LIST_INTERVIEW_SCHEDULE: 13,
  DETAIL_ORDER_LEADER: 14,
  DETAIL_JOB: 15,
  DETAIL_ORDER_HR: 16,
  DETAIL_APPLY_INTERVIEW_HR: 17,
  DETAIL_APPLY_TEST_HR: 18,
  NOT_REDIRECT: 20,
  LIST_KPI: 21,
  DETAIL_KPI: 22,
  LIST_REF_BONUS: 23,
  LIST_COST_RECRUIT: 24,
  DETAIL_CANDIDATE_HR: 25,
};
