import React, { useState } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { useParams } from 'react-router';
import { useQueryClient } from 'react-query';
import { confirmAlert } from 'react-confirm-alert';
import AlertComponent from 'components/Alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import styles from './style.module.scss';
import Loading from 'components/Loading';
import { WHEAT } from 'constants/ColorForm';
import { BG_POSITION } from 'constants/ColorMap';
import { USE_GET_INFOR_USER } from 'constants/DetailEmployee';
import { useGetInforUser, useGetPermissionUserInBo, useGetPermissionUserInWorkFollow } from 'hook/useDetailEmployee';
import { HTTP_OK } from 'constants/StatusCode';
import { apiUpdateInforUser } from 'api/detailEmployee';

const DetailEmployee = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const params = useParams();
  const [message, setMessage] = useState('');
  const [isShowAlert, setShowAlert] = useState(false);

  const { data: uGetInforUser, isLoading: isLoadingGetInforUser } = useGetInforUser({
    user_id: params.userId,
  });
  const { data: uGetPermissionUserInBo } = useGetPermissionUserInBo(params.userId);
  const { data: uGetPermissionUserInWorkFollow } = useGetPermissionUserInWorkFollow({ user_id: params.userId });

  const updateRoleAndStatus = (data, messages) => {
    confirmAlert({
      title: messages,
      buttons: [
        {
          label: t('common.cancel'),
          className: 'btn-alert-cancel',
        },
        {
          label: t('common.confirm'),
          className: 'btn-alert-ok',
          onClick: async () => {
            const response = await apiUpdateInforUser(params.userId, data);
            if (response && response?.data?.status === HTTP_OK) {
              await queryClient.invalidateQueries(USE_GET_INFOR_USER);
              setMessage(response?.data?.messages);
              setShowAlert(true);
            } else {
              if (response?.data?.messages?.status) {
                setMessage(response?.data?.messages?.status);
                setShowAlert(true);
              } else {
                setMessage(response?.data?.messages?.role);
                setShowAlert(true);
              }
            }
          },
        },
      ],
    });
  };

  return (
    <div className={styles.content}>
      <Grid container className={styles.backGroundCustom}>
        <div className={styles.title}>
          <div className={styles.textTitle}>
            <NavLink
              to={{
                pathname: '/member-management',
              }}
              className={styles.manageMember}
            >
              {t('menu.memberManagement')}
            </NavLink>
            <span className={styles.space}>/</span>{' '}
            <span className={styles.detail}>{t('memberManagement.detail')}</span>
          </div>
        </div>
      </Grid>
      <Grid container className={styles.backGroundCustom}>
        <div className={styles.title}>
          <div className={styles.labelTitle}></div>
          <div className={styles.textTitle}>{t('memberManagement.detailEmployee')}</div>
        </div>
      </Grid>
      <Grid container className={styles.boxInfor}>
        {uGetInforUser && (
          <>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <p className={styles.inforBasic}>{t('memberManagement.inforBasic')}</p>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <p className={styles.name}>{uGetInforUser?.data?.name}</p>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
              <p className={styles.inforTitle}>{t('memberManagement.code')}</p>
              <p className={styles.inforValue}> {uGetInforUser?.data?.code}</p>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
              <p className={styles.inforTitle}>{t('memberManagement.createdDate')}</p>
              <p className={styles.inforValue}> {uGetInforUser?.data?.created_at} </p>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
              <p className={styles.inforTitle}>{t('memberManagement.email')}</p>
              <p className={styles.inforValue}> {uGetInforUser?.data?.email} </p>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => {
                      updateRoleAndStatus(
                        { status: e.target.checked ? 1 : 0, role: uGetInforUser?.data?.role },
                        e.target.checked
                          ? t('memberManagement.AreYouSureYouWantToChangeTheStatusToActive')
                          : t('memberManagement.AreYouSureYouWantToChangeTheStatusToInActive'),
                      );
                    }}
                    sx={{
                      color: WHEAT,
                      '&.Mui-checked': {
                        color: BG_POSITION[0],
                      },
                    }}
                    checked={uGetInforUser?.data?.status ? true : false}
                  />
                }
                className={styles.label}
                label={'Active tài khoản'}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => {
                      updateRoleAndStatus(
                        { role: e.target.checked ? 1 : 0, status: uGetInforUser?.data?.status },
                        t('memberManagement.AreYouSureToConfirmTheAdminPermissionChange'),
                      );
                    }}
                    sx={{
                      color: WHEAT,
                      '&.Mui-checked': {
                        color: BG_POSITION[0],
                      },
                    }}
                    checked={uGetInforUser?.data?.role ? true : false}
                  />
                }
                className={styles.label}
                label={t('memberManagement.roleAdmin')}
              />
            </Grid>
          </>
        )}
        {isLoadingGetInforUser && <Loading addClass={styles.centerLoading} />}
      </Grid>
      <Grid container>
        <Grid item md={12} className={styles.assetMain}>
          <div className={styles.title}>
            <div className={styles.textTitleAuthorization}>{t('memberManagement.authorizationInformation')}</div>
          </div>
          <div className={styles.profileBox}>
            <Paper>
              <TableContainer>
                <Table aria-label="sticky table">
                  <TableHead className={styles.assetHeader}>
                    <TableRow className={styles.assetHeaderRow}>
                      <TableCell align="center" className={styles.short}>
                        {t('memberManagement.detailUser.nameSystem')}
                      </TableCell>
                      <TableCell align="center">{t('memberManagement.detailUser.role')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>{t('memberManagement.detailUser.acmsBo')}</TableCell>
                      <TableCell>
                        {uGetPermissionUserInBo?.permission ? uGetPermissionUserInBo?.permission : t('memberManagement.detailUser.notRole')}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t('memberManagement.detailUser.acmsMe')}</TableCell>
                      <TableCell>{t('memberManagement.detailUser.userNormal')}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t('memberManagement.detailUser.acmsRecruit')}</TableCell>
                      <TableCell>{t('memberManagement.detailUser.userNormal')}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t('memberManagement.detailUser.acmsWorkFlow')}</TableCell>
                      <TableCell>
                        {uGetPermissionUserInWorkFollow?.permission
                          ? uGetPermissionUserInWorkFollow?.permission
                          : t('memberManagement.detailUser.notRole')}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t('memberManagement.detailUser.acmsAccounting')}</TableCell>
                      <TableCell>{t('memberManagement.detailUser.userNormal')}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
        </Grid>
      </Grid>
      <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
        {message}
      </AlertComponent>
    </div>
  );
};

export default DetailEmployee;
